import { useEffect, useMemo } from "react";

import {
  confirmCancelTransaction,
  confirmTransaction,
  rejectTransaction,
  resetConfirmTransactionStates,
} from "store/transaction/slice";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import ActivityOverlay from "components/ActivityOverlay";
import RouteConfig from "routes/RouteConfig";
import TransactionDetails from "components/TransactionDetails/TransactionDetails";

import { TransactionSteps } from "utils/constants";
import { getDistributorDetails } from "utils/getDistributorDetails";
import moment from "moment";

const CancelSIPTransactionDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    transactionDetails,
    transactionDistributor,
    isConfirmingTransaction,
    didConfirmTransaction,
    confirmTransactionError,
    transactionSchemeDetails,
    isLoadingTransactionSchemesDetails,
    isRejectingTransaction,
    didRejectTransaction,
    rejectTransactionError,
  } = useAppSelector((state) => state.transaction);
  const { ihNo } = useAppSelector((state) => state.appState);

  useEffect(() => {
    if (didConfirmTransaction) {
      dispatch(resetConfirmTransactionStates());
      if (
        transactionDetails?.fetchTransactionDetails?.transactionType === "SIPC"
      ) {
        navigate(RouteConfig.cancelSipStpSwpSuccess);
      } else if (
        transactionDetails?.fetchTransactionDetails?.transactionType === "SWPC"
      ) {
        navigate(RouteConfig.cancelSwpSuccess);
      } else if (
        transactionDetails?.fetchTransactionDetails?.transactionType === "STPC"
      ) {
        navigate(RouteConfig.cancelStpSuccess);
      }
    }
  }, [didConfirmTransaction]);

  useEffect(() => {
    if (didRejectTransaction) {
      navigate(RouteConfig.cancelSipStpSwpTransactionReject);
    }
  }, [didRejectTransaction]);

  const transactions = useMemo(() => {
    if (
      !transactionDetails ||
      !transactionDetails.fetchTransactionDetails ||
      isLoadingTransactionSchemesDetails
    ) {
      return [];
    }

    const data: Array<any> = [];
    if (
      transactionDetails?.fetchTransactionDetails?.transactionType === "SIPC"
    ) {
      data.push({
        title: "SIP Cancellation Details",
        canEdit: false,
        type: "SIP_CANCEL",
        contents: {
          Scheme: transactionSchemeDetails.length
            ? transactionSchemeDetails[0].fundDesc
            : "",
          Option: transactionSchemeDetails.length
            ? `${transactionSchemeDetails[0]?.optDesc} - ${transactionSchemeDetails[0]?.planDesc}`
            : "",
          // "SIP Amount": transactionDetails.fetchTransactionDetails.amount
          //   ? transactionDetails.fetchTransactionDetails.amount
          //   : "",
          "Transaction Type": "Cancel SIP",
          Frequency: transactionDetails.fetchTransactionDetails.frequency
            ? transactionDetails.fetchTransactionDetails.frequency
            : "",
          // "SIP Date": "14",
          // "Start Date": transactionDetails.fetchTransactionDetails.startDate
          //   ? moment(transactionDetails.fetchTransactionDetails.startDate).format(
          //       "DD-MMM-YYYY"
          //     )
          //   : "",
          // "End Date": transactionDetails.fetchTransactionDetails.endDate
          //   ? moment(transactionDetails.fetchTransactionDetails.endDate).format(
          //       "DD-MMM-YYYY"
          //     )
          //   : "",

          "SIP cancel effective date": transactionDetails
            .fetchTransactionDetails.sipCancelEffectiveDate
            ? moment(
                transactionDetails.fetchTransactionDetails
                  .sipCancelEffectiveDate
              ).format("DD-MMM-YYYY")
            : "",
          // "Bank Name": transactionDetails.fetchTransactionDetails.bankName
          //   ? transactionDetails.fetchTransactionDetails.bankName
          //   : "",
        },
      });
    } else if (
      transactionDetails?.fetchTransactionDetails?.transactionType === "SWPC"
    ) {
      data.push({
        title: "SWP Cancellation Details",
        canEdit: false,
        type: "SWP_CANCEL",
        contents: {
          Scheme: transactionSchemeDetails.length
            ? transactionSchemeDetails[0].fundDesc
            : "",
          Option: transactionSchemeDetails.length
            ? `${transactionSchemeDetails[0]?.optDesc} - ${transactionSchemeDetails[0]?.planDesc}`
            : "",
          // "SIP Amount": transactionDetails.fetchTransactionDetails.amount
          //   ? transactionDetails.fetchTransactionDetails.amount
          //   : "",
          "Transaction Type": "Cancel SWP",
          Frequency: transactionDetails.fetchTransactionDetails.frequency
            ? transactionDetails.fetchTransactionDetails.frequency
            : "",
          // "SIP Date": "14",
          // "Start Date": transactionDetails.fetchTransactionDetails.startDate
          //   ? moment(transactionDetails.fetchTransactionDetails.startDate).format(
          //       "DD-MMM-YYYY"
          //     )
          //   : "",
          // "End Date": transactionDetails.fetchTransactionDetails.endDate
          //   ? moment(transactionDetails.fetchTransactionDetails.endDate).format(
          //       "DD-MMM-YYYY"
          //     )
          //   : "",

          "SWP cancel effective date": transactionDetails
            .fetchTransactionDetails.sipCancelEffectiveDate
            ? moment(
                transactionDetails.fetchTransactionDetails
                  .sipCancelEffectiveDate
              ).format("DD-MMM-YYYY")
            : "",
          // "Bank Name": transactionDetails.fetchTransactionDetails.bankName
          //   ? transactionDetails.fetchTransactionDetails.bankName
          //   : "",
        },
      });
    } else if (
      transactionDetails?.fetchTransactionDetails?.transactionType === "STPC"
    ) {
      data.push({
        title: "STP Cancellation Details",
        canEdit: false,
        type: "STP_CANCEL",
        contents: {
          Scheme: transactionSchemeDetails.length
            ? transactionSchemeDetails[0].fundDesc
            : "",
          Option: transactionSchemeDetails.length
            ? `${transactionSchemeDetails[0]?.optDesc} - ${transactionSchemeDetails[0]?.planDesc}`
            : "",
          // "SIP Amount": transactionDetails.fetchTransactionDetails.amount
          //   ? transactionDetails.fetchTransactionDetails.amount
          //   : "",
          "Transaction Type": "Cancel STP",
          Frequency: transactionDetails.fetchTransactionDetails.frequency
            ? transactionDetails.fetchTransactionDetails.frequency
            : "",
          // "SIP Date": "14",
          // "Start Date": transactionDetails.fetchTransactionDetails.startDate
          //   ? moment(transactionDetails.fetchTransactionDetails.startDate).format(
          //       "DD-MMM-YYYY"
          //     )
          //   : "",
          // "End Date": transactionDetails.fetchTransactionDetails.endDate
          //   ? moment(transactionDetails.fetchTransactionDetails.endDate).format(
          //       "DD-MMM-YYYY"
          //     )
          //   : "",

          "STP cancel effective date": transactionDetails
            .fetchTransactionDetails.sipCancelEffectiveDate
            ? moment(
                transactionDetails.fetchTransactionDetails
                  .sipCancelEffectiveDate
              ).format("DD-MMM-YYYY")
            : "",
          // "Bank Name": transactionDetails.fetchTransactionDetails.bankName
          //   ? transactionDetails.fetchTransactionDetails.bankName
          //   : "",
        },
      });
    }

    return data;
  }, [
    transactionDetails,
    transactionSchemeDetails,
    isLoadingTransactionSchemesDetails,
  ]);

  const handleTransactionApprove = () => {
    dispatch(
      confirmCancelTransaction({
        pan: transactionDetails.fetchTransactionDetails.investorPan,
        folio: transactionDetails.fetchTransactionDetails.folioNumber,
        cancelTxnReferenceNo: transactionDetails.fetchTransactionDetails.ihno,
        userId: transactionDetails.fetchTransactionDetails.brokerCode,
        arnCode: transactionDetails.fetchTransactionDetails.brokerCode,

        // ihNo: transactionDetails.fetchTransactionDetails.ihno,
        // txnType: "SIPC",
      })
    );
  };

  const handleTransactionReject = () => {
    const distributor = getDistributorDetails(transactionDistributor);
    dispatch(
      rejectTransaction({
        batchNo: transactionDetails.fetchTransactionDetails.batchNo,
        pan: transactionDetails.fetchTransactionDetails.investorPan,
        ...distributor,
        rejectReason: "Not Intrested",
      })
    );
  };

  const handleEditTransaction = (data: any, _type: string | undefined) => {};
  return (
    <>
      {!isLoadingTransactionSchemesDetails ? (
        <TransactionDetails
          stepper={{ currentIndex: 0, steps: TransactionSteps }}
          error={confirmTransactionError || rejectTransactionError}
          transactions={transactions}
          onApproveTranaction={handleTransactionApprove}
          onEditTransaction={handleEditTransaction}
          onRejectTranaction={handleTransactionReject}
        />
      ) : null}
      {(isConfirmingTransaction ||
        isLoadingTransactionSchemesDetails ||
        isRejectingTransaction) && <ActivityOverlay />}
    </>
  );
};
export default CancelSIPTransactionDetails;
