import { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import ButtonIcon from "components/ButtonIcon";
import { SelectOption } from "components/FormSelect";
import Select from "components/Select";
import Text from "components/Text";
import ToggleButtonOptions from "components/ToggleButton/ToggleButtonOptions";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useState, useMemo } from "react";
import colors from "res/colors";
import isMobile from "utils/isMobile";
import ActivityOverlay from "components/ActivityOverlay/ActivityOverlay";
import {
  didFailToGetBilldeskUrl,
  getBilldeskUrl,
  getOtmBilldeskUrl,
  loadOtmConfirmation,
  resetBilldeskError,
  resetOtmConfirmation,
  setTransactionApproval,
} from "store/transaction/slice";
import _ from "lodash";
import moment from "moment";
import { Navigate, useNavigate } from "react-router-dom";
import RouteConfig from "routes/RouteConfig";
import { decrementTransactionStage } from "store/appstate/slice";
import ApproveConfirmationDialog from "components/TransactionDetails/ApproveConfirmationDialog";
import { convertToCurrencyFormat } from "utils/convertToCurrencyFormat";
import { TransactionType } from "utils/constants";

const bankOtmMode = [
  { label: "UPI" },
  { label: "Net Banking" },
  { label: "OTM" },
];
const bankMode = [{ label: "UPI" }, { label: "Net Banking" }];
const PaymentOptions = () => {
  const dispatch = useAppDispatch();
  const {
    transactionDetails,
    paymentOptions,
    isGettingBilldeskUrl,
    billdeskUrlError,
    billdeskUrl,
    // isOtmBankDataPresent,
    otmRegisteredBankData,
    otmConfirmError,
    isLoadingOtmConfirm,
    didLoadOtmConfirm,
  } = useAppSelector((state) => state.transaction);
  const navigate = useNavigate();
  const apiPaymentMode =
    transactionDetails?.fetchFreshCartDataResponseList?.payMode ||
    transactionDetails?.fetchOtmResponse?.otmAuthenticationMode ||
    "";
  const { transactionType } = useAppSelector((state) => state.appState);

  const [isOtmBankDataPresent, setIsOtmBankDataPresent] = useState(false);
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedBankError, setSelectedBankError] = useState("");
  const [selectedBankDetails, setSelectedBankDetails] = useState<any>("");
  const [otmAlert, setOtmAlert] = useState(false);
  const [endDateAlert, setEndDateAlert] = useState(false);
  const [ifscErrorAlert, setIfscErrorAlert] = useState(false);
  const [sipOtmError, setSipOtmError] = useState("");
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [selectedBankMode, setSelectedBankMode] = useState(
    apiPaymentMode === "Netbanking"
      ? "Net Banking"
      : apiPaymentMode === "Debit Card"
      ? "UPI"
      : apiPaymentMode || "UPI"
  );

  useEffect(() => {
    if (billdeskUrl) {
      //This is POST url call implementation to hide the confidential data in billdesk url wich shows in browser address bar.
      let billdeskUrlArray = billdeskUrl?.split("?");
      const finalURLVAluesArray = billdeskUrlArray[1]
        ?.split("&")
        ?.map((ele: any) => ele.split("=")[1]);

      // Create form element
      const form = document.createElement("form");
      form.id = "myForm";
      form.method = "post";
      form.action = billdeskUrlArray[0];

      if (transactionDetails.fetchOtmResponse) {
        //this is for OTP billdesk url
        const action = document.createElement("input");
        action.type = "hidden";
        action.name = "action";
        action.value = finalURLVAluesArray[0];

        const msg = document.createElement("input");
        msg.type = "hidden";
        msg.name = "msg";
        msg.value = finalURLVAluesArray[1];

        // Create submit button
        const submitBtn = document.createElement("button");
        submitBtn.type = "submit";
        submitBtn.textContent = "Submit";

        // Append elements to the form
        form.appendChild(action);
        form.appendChild(msg);
        form.appendChild(submitBtn);
      } else {
        // Create hidden input fields
        const hidRequestId = document.createElement("input");
        hidRequestId.type = "hidden";
        hidRequestId.name = "hidRequestId";
        hidRequestId.value = finalURLVAluesArray[0];

        const hidOperation = document.createElement("input");
        hidOperation.type = "hidden";
        hidOperation.name = "hidOperation";
        hidOperation.value = finalURLVAluesArray[1];

        const msg = document.createElement("input");
        msg.type = "hidden";
        msg.name = "msg";
        msg.value = finalURLVAluesArray[2];

        // Create submit button
        const submitBtn = document.createElement("button");
        submitBtn.type = "submit";
        submitBtn.textContent = "Submit";

        // Append elements to the form
        form.appendChild(hidRequestId);
        form.appendChild(hidOperation);
        form.appendChild(msg);
        form.appendChild(submitBtn);
      }
      // Append the form to the document body or desired container
      document.body.appendChild(form);
      form.submit();
    }
  }, [billdeskUrl]);

  useEffect(() => {
    if (didLoadOtmConfirm) {
      navigate(RouteConfig.otmTransactionSuccess);
    } else if (otmConfirmError) {
      navigate(RouteConfig.otmPaymentFail);
    }
    return () => {
      dispatch(resetOtmConfirmation());
      dispatch(resetBilldeskError());
    };
  }, [didLoadOtmConfirm, otmConfirmError]);

  const isDateIsBeforeThirtyYears = (
    endDate: Date | string,
    startDate: Date | string
  ) => {
    return moment(moment(endDate).format("YYYY-MM-DD")).isBefore(
      moment(startDate).add(30, "y").format("YYYY-MM-DD")
    );
  };
  const checkForSchemeWithLessThanThirtyYears = () => {
    //added this check for SIP-OTM when sip startdate and endate has more than 30 years difference will hide OTM option
    if (transactionType === TransactionType.sip) {
      const res = transactionDetails?.cartInitiateResponse?.schemeList?.some(
        (ele: any) =>
          !isDateIsBeforeThirtyYears(ele?.sipEndDate, ele?.sipStartDate) ===
          true
      );
      return !res;
    } else {
      return true;
    }
  };

  useEffect(() => {
    setIsOtmBankDataPresent(false);
    if (
      otmRegisteredBankData &&
      otmRegisteredBankData?.length > 0 &&
      checkForSchemeWithLessThanThirtyYears()
    ) {
      setIsOtmBankDataPresent(true);
    }
  }, [otmRegisteredBankData]);

  const otmBankOptions = useMemo(() => {
    if (!otmRegisteredBankData || !otmRegisteredBankData.length) {
      return [];
    }
    return otmRegisteredBankData.map((item: any) => ({
      ...item,
      title: `${item.bankName} ${
        item?.bankAccNo ? " - xx" + item?.bankAccNo?.toString().slice(10) : ""
      } ${
        item?.amount
          ? " - (Max: ₹" + convertToCurrencyFormat(item?.amount)?.toString()
          : ""
      })`,
      value: `${item.bankName} ${
        item?.bankAccNo ? " - xx" + item?.bankAccNo?.toString().slice(10) : ""
      } ${
        item?.amount
          ? " - (Max: ₹" + convertToCurrencyFormat(item?.amount)?.toString()
          : ""
      })`,
    }));
  }, [otmRegisteredBankData]);

  const bankOptions = useMemo(() => {
    if (!paymentOptions || !paymentOptions.length) {
      return [];
    }
    if (
      transactionDetails?.cartInitiateResponse &&
      selectedBankMode &&
      selectedBankMode?.toUpperCase() === "NET BANKING"
    ) {
      return paymentOptions
        .filter((item: any) => item?.bankId)
        .map((item: any) => ({
          ...item,
          title: `${item.bankName} ${
            item?.bankAcno ? " - " + item?.bankAcno?.toString() : ""
          }`,
          value: item.bankName,
        }));
    }
    return paymentOptions.map((item: any) => ({
      ...item,
      title: `${item.bankName} ${
        item?.bankAcno ? " - " + item?.bankAcno?.toString() : ""
      }`,
      value: item.bankName,
    }));
  }, [paymentOptions, selectedBankMode]);

  const handleSelectBank = (option: SelectOption) => {
    setSelectedBankError("");
    const tempData = JSON.stringify(option);
    const bankData = JSON.parse(tempData);
    // const bankData = {
    //   ifscCode: "",
    // };
    if (selectedBankMode === "UPI") {
      if (
        !bankData?.ifscCode ||
        (bankData?.ifscCode &&
          (bankData?.ifscCode === "" || bankData?.ifscCode === null))
      ) {
        // alert(
        //   "We are unable to process the transaction using UPI. Please select other payment option."
        // );
        setIfscErrorAlert(true);
        return;
      }
    }
    if (selectedBankMode === "OTM" && transactionType === TransactionType.sip) {
      const resAmount =
        transactionDetails?.cartInitiateResponse?.schemeList?.some(
          (ele: any) => parseInt(ele?.amount) > parseInt(bankData?.amount)
        );
      const resDate =
        transactionDetails?.cartInitiateResponse?.schemeList?.some(
          (ele: any) => {
            return (
              moment(
                moment(ele?.sipEndDate).format("YYYY-MM-DD")
              ).isSameOrBefore(
                moment(bankData?.endDate).format("YYYY-MM-DD")
              ) === false
            );
          }
        );

      if (resAmount) {
        setSipOtmError("SIP amount is more than the registered OTM amount.");
      } else if (resDate) {
        setSipOtmError("SIP end date exceeds the OTM end date.");
      } else {
        setSipOtmError("");
      }
    }

    setSelectedBankDetails(option);
    setSelectedBank(option.value);
  };

  const handleBankMode = (selectedOption: string) => {
    setSelectedBankMode(selectedOption);
    setSelectedBankDetails("");
    setSelectedBank("");
    setSipOtmError("");
  };

  const handleShowApproveDialog = () => {
    setSelectedBankError("");
    if (!selectedBank) {
      setSelectedBankError("Please select a bank.");
      return;
    }
    if (sipOtmError) {
      return;
    }
    setShowApproveDialog(true);
  };

  const closeApproveDialog = () => {
    setShowApproveDialog(false);
  };

  const handlePayment = () => {
    setShowApproveDialog(false);
    setOtmAlert(false);
    setEndDateAlert(false);
    if (!selectedBank) {
      return;
    }

    if (selectedBankMode === "OTM") {
      if (selectedBankDetails && Object.keys(selectedBankDetails)?.length > 0) {
        const totalAmount =
          transactionDetails?.cartInitiateResponse?.schemeList?.reduce(
            (previousValue: number, currentValue: any) =>
              previousValue + parseFloat(currentValue.amount),
            0
          );
        const bankEndDate = moment(selectedBankDetails?.endDate);
        const todayDate = moment();

        if (totalAmount <= Number(selectedBankDetails?.amount)) {
          if (moment(todayDate).isSameOrBefore(bankEndDate, "days")) {
            const params = {
              urnNo:
                transactionDetails?.cartInitiateResponse?.schemeList[0].urno ||
                "NA",
              transactionDateTime:
                transactionDetails?.cartInitiateResponse?.transactionDateTime ||
                "",
              batchNo: transactionDetails?.cartInitiateResponse?.batchNo || "",
              bankRefno: "",
              paymentId: "",
              accountNumber: "",
              paymentMode: "NACH",
              billDeskFeed: "",
              transactionStatus: "Success",
              bankName: selectedBankDetails?.bankName,
              bankAccountType: selectedBankDetails?.bankAccType
                ? selectedBankDetails?.bankAccType === "Savings"
                  ? "SB"
                  : "CA"
                : "",
              ifsc: selectedBankDetails?.ifsc || "",
              umrn: selectedBankDetails?.umrn,
              bankAccountNumber: selectedBankDetails?.bankAccNo || "",
            };
            dispatch(loadOtmConfirmation(params));
          } else {
            setEndDateAlert(true);
          }
        } else {
          setOtmAlert(true);
        }
      }
      return; //Add api call for otm confirm transaction
    }

    const bank = paymentOptions.filter(
      (item: any) => item.bankName === selectedBank
    );
    if (bank) {
      let doesContainEmpty = false;
      const tempBank = { ...bank[0] };
      delete tempBank?.micrCode; //removed mandatory check for micr
      if (transactionDetails?.cartInitiateResponse) {
        delete tempBank?.bankId; //removed mandatory check for bankId for existing investor
      }
      const tempObj = Object.values(tempBank);

      const temp = tempObj.map((data) => {
        if (
          data === null ||
          data === "" ||
          data === undefined ||
          String(data).trim() === ""
        ) {
          doesContainEmpty = true;
        }
      });
      if (doesContainEmpty) {
        dispatch(
          didFailToGetBilldeskUrl(
            "Technical issue. Please contact your distributor."
          )
        );
        return;
      }
      let suborders;
      if (transactionDetails.fetchFreshCartDataResponseList) {
        suborders =
          transactionDetails.fetchFreshCartDataResponseList.schemeList.map(
            //changed here
            (transaction: any) => ({
              ihNumber: transaction.ihNo,
              schemeCode: transaction.scheme,
              amount: parseFloat(transaction.amount).toFixed(2),
            })
          );
      } else if (transactionDetails.cartInitiateResponse) {
        suborders = transactionDetails.cartInitiateResponse.schemeList.map(
          //changed here
          (transaction: any) => ({
            ihNumber: transaction.ihNo,
            schemeCode: transaction.scheme,
            amount: parseFloat(transaction.amount).toFixed(2),
          })
        );
      } else if (transactionDetails.fetchOtmResponse) {
        suborders = [
          {
            amount: parseFloat(
              transactionDetails.fetchOtmResponse.amount
            ).toFixed(2),
            schemeCode: "NA",
            ihNumber: transactionDetails.fetchOtmResponse.payoutRefNo,
          },
        ];
        //
        //   .map(
        //   //changed here
        //   (transaction: any) => ({
        //     ihNumber: transaction.ihNo,
        //     schemeCode: transaction.scheme,
        //     amount: parseFloat(transaction.amount).toFixed(2),
        //   })
        // );
      }
      const total = suborders.reduce(
        (previousValue: number, currentValue: any) =>
          previousValue + parseFloat(currentValue.amount),
        0
      );

      if (transactionDetails.fetchFreshCartDataResponseList) {
        const paymentMode =
          selectedBankMode.toUpperCase() === "NET BANKING"
            ? "NETBANKING"
            : selectedBankMode.toUpperCase();

        dispatch(
          getBilldeskUrl({
            paymentMode: paymentMode,
            totalAmount: total.toFixed(2),
            mainOrderUniqueId:
              transactionDetails?.fetchFreshCartDataResponseList?.batchNo,
            accountNumber:
              transactionDetails?.fetchFreshCartDataResponseList
                ?.payoutBankAcNo,
            bankIfsc:
              transactionDetails?.fetchFreshCartDataResponseList
                ?.payoutIFSCCode,
            bankName:
              transactionDetails?.fetchFreshCartDataResponseList
                ?.payoutBankName,
            urnNo:
              transactionDetails?.fetchFreshCartDataResponseList?.schemeList[0]
                .urno || "NA",
            // bankId: "HDF",
            bankId: bank[0]?.bankId,
            // folioNo: bank[0].folio,
            emailId: transactionDetails?.fetchFreshCartDataResponseList?.mailID,
            subOrderData: suborders,
            investorName:
              transactionDetails?.fetchFreshCartDataResponseList?.lname ||
              "test",
            source: "WEB",
            mobileNumber:
              transactionDetails?.fetchFreshCartDataResponseList?.ap1MobileNumber?.charAt(
                0
              ) === "+"
                ? transactionDetails?.fetchFreshCartDataResponseList?.ap1MobileNumber?.slice(
                    1
                  )
                : transactionDetails?.fetchFreshCartDataResponseList
                    ?.ap1MobileNumber,
          })
        );
      } else if (transactionDetails?.cartInitiateResponse) {
        const paymentMode =
          selectedBankMode.toUpperCase() === "NET BANKING"
            ? "NETBANKING"
            : selectedBankMode.toUpperCase();

        dispatch(
          getBilldeskUrl({
            paymentMode: paymentMode,
            totalAmount: total?.toFixed(2),
            mainOrderUniqueId:
              transactionDetails?.cartInitiateResponse?.batchNo,
            accountNumber: bank[0]?.bankAcno,
            bankIfsc: bank[0]?.ifscCode,
            bankName: bank[0]?.bankName,
            accountType:
              bank[0]?.accountType === "SAVINGS"
                ? "SB"
                : bank[0]?.accountType === "CURRENT"
                ? "CB"
                : bank[0]?.accountType,
            urnNo:
              transactionDetails?.cartInitiateResponse?.schemeList[0].urno ||
              "NA",
            bankId: bank[0]?.bankId,
            folioNo: bank[0]?.folio,
            emailId: transactionDetails?.cartInitiateResponse?.mailID,
            subOrderData: suborders,
            investorName:
              transactionDetails?.cartInitiateResponse?.lname || "test",
            source: "WEB",
            mobileNumber:
              transactionDetails?.cartInitiateResponse?.mobile?.charAt(0) ===
              "+"
                ? transactionDetails?.cartInitiateResponse?.mobile?.slice(1)
                : transactionDetails?.cartInitiateResponse?.mobile,
          })
        );
      } else if (transactionDetails?.fetchOtmResponse) {
        const paymentMode =
          selectedBankMode.toUpperCase() === "NET BANKING"
            ? "NETBANKING"
            : selectedBankMode.toUpperCase();
        dispatch(
          getOtmBilldeskUrl({
            uniqueRegNumberOTM:
              transactionDetails?.fetchOtmResponse.payoutRefNo,
            accountNumber: bank[0]?.bankAcno,
            bankIfscCode: bank[0]?.ifscCode,
            // bankName: bank[0].bankName,
            // bankCode: bank[0].bankId,
            // bankId: bank[0].bankId,
            folioNo: bank[0]?.folio,
            investorEmailId: transactionDetails?.fetchOtmResponse?.emailId,
            userName: transactionDetails?.fetchOtmResponse?.investorName,
            investorMobileNumber:
              transactionDetails?.fetchOtmResponse?.mobileNo,
            modeOfAuthentication:
              transactionDetails?.fetchOtmResponse?.otmAuthenticationMode?.toUpperCase(),

            // amount: transactionDetails.fetchOtmResponse.amount,
            // arnCode: transactionDetails.fetchOtmResponse.arnCode,
            // pan: transactionDetails.fetchOtmResponse.pan,
            // schemeCode: "NA",
            // appName: "DISTRIBUTOR",
            accountType:
              transactionDetails?.fetchOtmResponse?.acType === "SAVINGS"
                ? "SB"
                : transactionDetails?.fetchOtmResponse?.acType === "CURRENT"
                ? "CB"
                : transactionDetails?.fetchOtmResponse?.acType,
            maximumOTMAmount: transactionDetails?.fetchOtmResponse?.amount,
            otmAmountType: transactionDetails?.fetchOtmResponse?.otmAmountType,
            otmStartDate: moment(
              transactionDetails.fetchOtmResponse?.otmStartDate,
              "MMM DD YYYY hh:mmA"
            ).format("YYYYMMDD"),
            otmEndDate: moment(
              transactionDetails?.fetchOtmResponse?.otmEndDate,
              "MMM DD YYYY hh:mmA"
            ).format("YYYYMMDD"),
            otmFrequency:
              transactionDetails?.fetchOtmResponse?.otmFrequency ===
              "As and when presented"
                ? "ADHO"
                : transactionDetails?.fetchOtmResponse?.otmFrequency,
            // nationality: "NA",
          })
        );
      }
    }
  };
  const showError = Boolean(billdeskUrlError);
  const otmError = Boolean(otmConfirmError || sipOtmError);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          mt: isMobile() ? "30%" : "15%",
          mx: "5%",
          width: isMobile() ? "90%" : "50%",
        }}
      >
        <Card sx={{ mt: "12px", pb: "32px", pt: "24px", px: "20px" }}>
          <Text type="largeBold">Payment</Text>
          <Box sx={{ my: "20px" }}>
            <Divider sx={{ backgroundColor: colors.backGroundBlue }} />
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap", maxWidth: 300 }}>
            <ToggleButtonOptions
              title="Select Payment Mode"
              options={isOtmBankDataPresent ? bankOtmMode : bankMode}
              selectMultiple={false}
              setBorder={true}
              value={selectedBankMode}
              onClick={handleBankMode}
            />
          </Box>
          <Box sx={{ my: "24px" }}>
            <Divider />
          </Box>

          <Select
            placeHolder="Select Bank"
            label="Select Bank"
            options={selectedBankMode === "OTM" ? otmBankOptions : bankOptions}
            onOptionSelect={handleSelectBank}
            value={selectedBank}
            errorMessage={selectedBankError}
          />

          {selectedBankMode === "OTM" && selectedBankDetails ? (
            <Text
              type="mediumBold"
              sx={{ marginTop: "20px", marginBottom: "5px" }}
            >
              {`OTM End Date : ${moment(selectedBankDetails?.endDate).format(
                "DD-MMM-YYYY"
              )}`}
            </Text>
          ) : null}
        </Card>
        {showError ? (
          <Text
            type="mediumBold"
            color="red"
            sx={{ marginTop: "10px", marginBottom: "15px" }}
          >
            {billdeskUrlError}
          </Text>
        ) : null}
        {otmError ? (
          <Text
            type="mediumBold"
            color="red"
            sx={{ marginTop: "10px", marginBottom: "15px" }}
          >
            {otmConfirmError || sipOtmError}
          </Text>
        ) : null}
        <Box sx={{ mt: "53px", mx: "5%" }}>
          <ButtonIcon
            title={"Proceed To Payment"}
            icon={true}
            // onClick={handlePayment}
            onClick={handleShowApproveDialog}
          ></ButtonIcon>
        </Box>
        {showApproveDialog ? (
          <ApproveConfirmationDialog
            onClose={closeApproveDialog}
            onApprove={handlePayment}
          />
        ) : null}
      </Box>
      <Dialog
        open={otmAlert}
        onClose={() => {
          setOtmAlert(false);
        }}
      >
        <DialogTitle>OTM</DialogTitle>
        <DialogContent>
          <Text
            type="dataSmallBold"
            color={"#00000080"}
            sx={{
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "20px",
              fontFamily: "Graphik",
              letterSpacing: "-0.32px",
            }}
          >
            {`Investment Amount should not be greater than the OTM limit Rs.${selectedBankDetails?.amount}.`}
          </Text>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOtmAlert(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setOtmAlert(false);
              // dispatch(decrementTransactionStage());
              navigate(-1);
            }}
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={endDateAlert}
        onClose={() => {
          setEndDateAlert(false);
        }}
      >
        <DialogTitle>OTM</DialogTitle>
        <DialogContent>
          <Text
            type="dataSmallBold"
            color={"#00000080"}
            sx={{
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "20px",
              fontFamily: "Graphik",
              letterSpacing: "-0.32px",
            }}
          >
            {`Your mandate has expired on ${moment(
              selectedBankDetails?.endDate
            ).format(
              "DD/MM/YYYY"
            )}, Please register a new mandate and then renew your ${
              transactionDetails?.cartInitiateResponse?.transactionType ===
              "SIN"
                ? "SIP"
                : "Lumpsum"
            }.`}
          </Text>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setEndDateAlert(false);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={ifscErrorAlert}
        onClose={() => {
          setIfscErrorAlert(false);
        }}
      >
        {/* <DialogTitle>OTM</DialogTitle> */}
        <DialogContent>
          <Text
            type="dataSmallBold"
            color={"#00000080"}
            sx={{
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "20px",
              fontFamily: "Graphik",
              letterSpacing: "-0.32px",
            }}
          >
            {
              "We are unable to process the transaction using UPI. Please select other payment option."
            }
          </Text>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIfscErrorAlert(false);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {(isGettingBilldeskUrl || isLoadingOtmConfirm) && <ActivityOverlay />}
    </Box>
  );
};

export default PaymentOptions;
